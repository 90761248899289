.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main {

  border-radius: 25px;
  padding: 30px;
  opacity: 0.5;
  background-color: white;
  margin: 5%;
  color: black;
  font-size: 2em;

  @media (max-width: 400px) {
    font-size: 1em;
  }

 li {
  margin-bottom: 10px;
 } 
}
.footer
{
  padding: 5px;
  background-color: #638cba;
  font-size: 0.8em;
  color: white;
  a, a:hover, a:visited {
    color: white;
  }
}